<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import Loader from '../../components/Loader.svelte'
  import { getData, postData } from '../../utils/ApiUtils.svelte'
  import { onMount } from 'svelte'
  import { userDetails } from '../../components/DataStore'

  let courseList = []

  let courseStatus = 'loading'

  onMount(async () => {
    getPremiumCourseList()
  })

  function getPremiumCourseList() {
    getData('studentapi/course-marketplace/projection')
      .then((data) => {
        console.log('course successful', data)

        courseList = data
        courseStatus = 'ok'
      })
      .catch((error) => {
        courseStatus = 'error'
        console.log('error', error)
      })
  }

  function enrollInPremiumCourse(id) {
    mixpanel.track('enrollInPremiumCourse', {
      classID: id,
    })
    console.log('viewCourseDetail', id)
    // router.goto('/course/' + id)

    let enrollInPremiumClassJson = {
      courseId: id,
      studentAccountId: $userDetails.id,
    }

    courseStatus = 'processing'

    postData('studentapi/course/enroll', enrollInPremiumClassJson)
      .then((data) => {
        console.log('login successful', data)
        courseStatus = 'changed'
        router.goto(`/course/${id}`)
      })
      .catch((error) => {
        console.log('error login', error)
        courseStatus = 'error'
        errorMessage = $_('PASSWORD_CHANGE_ERROR')
      })
  }

  function continueWithCourse(id) {
    router.goto(`/course/${id}`)
  }

  function upgrade(id) {}
</script>

<h2>{$_('PREMIUM_SECTION.CLASSES')}</h2>
<!-- <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item">{$_("COURSE_LIST")}</li>
    </ol> -->
<div class="row">
  {#if courseStatus === 'loading'}
    <Loader />
  {:else if courseStatus === 'error'}
    {$_('PREMIUM_SECTION.ERROR_LOADING_CLASS_LIST')}
  {:else}
    {#each courseList as c, i}
      <div class="col-xl-3 col-md-6">
        <div class="card mb-4">
          <div class="card-header">
            <i class="far fa-star" />
            {$_('PREMIUM_SECTION.FREE')}
            <!-- {c.exerciseDoneCount}/{c.exerciseCount} -->
          </div>
          <div class="card-body course-card-body">{c.courseName}</div>

          {#if c?.csaeState === 'ACTIVE'}
            <div
              class="card-footer d-flex align-items-center justify-content-between bg-success"
            >
              <span
                class="small stretched-link"
                role="button"
                on:click={() => {
                  continueWithCourse(c.courseId)
                }}>{$_('PREMIUM_SECTION.CONTINUE')}</span
              >
              <div class="small"><i class="fas fa-angle-right" /></div>
            </div>
          {:else if c?.csaeState === null}
            <div
              class="card-footer d-flex align-items-center justify-content-between"
            >
              <span
                class="small stretched-link"
                role="button"
                on:click={() => {
                  enrollInPremiumCourse(c.courseId)
                }}>{$_('PREMIUM_SECTION.JOIN_COURSE')}</span
              >
              <div class="small"><i class="fas fa-angle-right" /></div>
            </div>
          {/if}
          <!-- {#if c?.price === 'FREE'}
            <div
              class="card-footer d-flex align-items-center justify-content-between"
            >
              <span
                class="small stretched-link"
                role="button"
                on:click={() => {
                  enrollInPremiumCourse(c.courseId)
                }}>{$_('PREMIUM_SECTION.JOIN_COURSE')}</span
              >
              <div class="small"><i class="fas fa-angle-right" /></div>
            </div>
          {:else}
            <div
              class="card-footer d-flex align-items-center justify-content-between bg-danger text-white"
            >
              <span
                class="small stretched-link "
                role="button"
                on:click={() => {
                  upgrade(c.courseId)
                }}>{$_('PREMIUM_SECTION.UPGRADE')}</span
              >
            </div>
          {/if} -->
        </div>
      </div>
    {:else}
      <div class="col align-self-center">
        <div class="text-center">
          <h3>{$_('PREMIUM_SECTION.NO_CLASS_PREMIUM')}</h3>
        </div>
      </div>
    {/each}
  {/if}
</div>
