<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import Loader from '../../components/Loader.svelte'
  import { getData, postData } from '../../utils/ApiUtils.svelte'
  import { onMount } from 'svelte'
  import { userDetails } from '../../components/DataStore'
  import Tooltip from '../../components/Tooltip.svelte'
  import { BASE_URL } from '../../components/DataStore'

  let analyticsResultList = []

  let analyticsResultStatus = 'loading'

  let analyticsType = 'words'

  onMount(() => {
    getAnalyticsResultList()
  })

  function getAnalyticsResultList() {
    if ($userDetails?.login) {
      analyticsResultStatus = 'loading'
      getData(`${BASE_URL}studentapi/analytics/${analyticsType}`, true)
        .then((data) => {
          console.log('course successful', data)

          analyticsResultList = data
          analyticsResultStatus = 'ok'
        })
        .catch((error) => {
          analyticsResultStatus = 'error'
          console.log('error', error)
        })
    }
  }
</script>

<main>
  <div class="container-fluid px-4">
    <h1 class="mt-4">{$_('ANALYTICS_SECTION.ANALYTICS')}</h1>
    <p>{$_('ANALYTICS_SECTION.SENSAY_ANALYTICS_INTRODUCTION')}</p>
    <!-- <div><UpgradeToPremium bind:this={upgradeModal} /></div> -->

    <div class="row">
      <div class="col-auto">
        <select
          class="form-select"
          style="min-width:15em;"
          bind:value={analyticsType}
          on:change={getAnalyticsResultList}
        >
          <option value="words">{$_('ANALYTICS_SECTION.WORDS')}</option>
          <option value="syllable">{$_('ANALYTICS_SECTION.SYLLABLE')}</option>
          <option value="phoneme">{$_('ANALYTICS_SECTION.PHONEME')}</option>
        </select>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-8-auto">
        {#if analyticsResultStatus === 'loading'}
          <Loader />
        {:else if analyticsResultStatus === 'error'}
          {$_('ANALYTICS_SECTION.ERROR_LOADING_ANALYTICS')}
        {:else}
          <table class="table">
            <tr
              ><th scope="col">#</th>
              <th scope="col">{$_('ANALYTICS_SECTION.ITEM')}</th>
              <th scope="col">
                <Tooltip tooltipText={$_('ANALYTICS_SECTION.COUNT_TOOLTIP')}
                  >{$_('ANALYTICS_SECTION.COUNT')}</Tooltip
                ></th
              >
              <th scope="col"
                ><Tooltip tooltipText={$_('ANALYTICS_SECTION.MIN_TOOLTIP')}
                  >{$_('ANALYTICS_SECTION.MIN')}</Tooltip
                ></th
              >
              <th scope="col">
                <Tooltip tooltipText={$_('ANALYTICS_SECTION.MEAN_TOOLTIP')}
                  >{$_('ANALYTICS_SECTION.MEAN')}</Tooltip
                ></th
              >
              <th scope="col">
                <Tooltip tooltipText={$_('ANALYTICS_SECTION.MAX_TOOLTIP')}
                  >{$_('ANALYTICS_SECTION.MAX')}</Tooltip
                ></th
              >
              <th scope="col">{$_('ANALYTICS_SECTION.VISUALIZATION')}</th>
            </tr>
            <tbody>
              {#each analyticsResultList as exam, i}
                <tr>
                  <th scope="row">{i + 1}</th>
                  <td>{exam?.item}</td>
                  <td>{exam?.count}</td>
                  <td>{exam?.min}</td>
                  <td>{exam?.mean.toFixed(0)}</td>
                  <td>{exam?.max}</td>
                  <td
                    ><div class="visualization-wrapper">
                      {#each exam?.values as x}
                        {#if x > 80}
                          <span class="point-green" />
                        {:else if x > 50}
                          <span class="point-orange" />
                        {:else}
                          <span class="point-red" />
                        {/if}
                      {/each}
                    </div></td
                  >
                </tr>
              {:else}
                {$_('ANALYTICS_SECTION.NO_DATA')}
              {/each}
            </tbody>
          </table>
        {/if}
      </div>
    </div>
  </div>
</main>

<style>
  .visualization-wrapper {
    display: flex;
  }

  .point-green {
    width: 2px;
    height: 1em;
    background-color: rgb(64, 255, 0);
  }

  .point-orange {
    width: 2px;
    height: 1em;
    background-color: rgb(251, 255, 0);
  }

  .point-red {
    width: 2px;
    height: 1em;
    background-color: rgb(255, 13, 0);
  }

  .header-done {
    background-color: #00a094ff;
    color: #f4f4f4;
  }

  .header-in-progress {
    background-color: #ffcc07ff;
    color: black;
  }

  .header-new {
  }

  .course-card-body {
    min-height: 4rem;
  }
</style>
