import { writable } from 'svelte/store';

// export const BASE_URL = "https://api.oksensay.com/"
export const BASE_URL = "https://api2.oksensay.com/"
// export const BASE_URL = 'http://192.241.145.55:8088/'
// export const BASE_URL = 'http://localhost:8088/'
// export const BASE_URL = 'https://loquacious-dragon-629ce9.netlify.app/'

export const loginToken = writable(null);
export const userDetails = writable(null);

export const userConfig = writable({ "featureSwitch": {} });

export const userContext = writable(null);

export const mainMenuToggle = writable(false);
export const avatarImageSrc = writable(null);

export const exerciseList = writable([]);
export const exercisePos = writable(0);

export const apiToken = writable("");
export const apiDomain = writable("");